import styled from 'styled-components';

export const List = styled.ul`
  padding: 0;

  list-style: none;

  ${({ theme }) => theme.layouts.stack(`${theme.spaces.sm4}rem`)};
`;

export const ListItem = styled.li``;
