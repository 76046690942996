// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Hero from '../../../components/Hero';

const query = graphql`
  query {
    heroPictureDesktop: file(name: { eq: "img-hero-desktop-business-page" }) {
      childImageSharp {
        ...HeroPicturesDesktop
      }
    }
    heroPictureMobile: file(name: { eq: "img-hero-mobile-business-page" }) {
      childImageSharp {
        ...HeroPicturesMobile
      }
    }
  }
`;

const HeroSection = ({ children }) => {
  const data = useStaticQuery(query);

  const {
    heroPictureDesktop: {
      childImageSharp: { desktop },
    },
    heroPictureMobile: {
      childImageSharp: { mobile },
    },
  } = data;

  const pictures = {
    childImageSharp: {
      desktop,
      mobile,
    },
  };

  return <Hero pictures={pictures}>{children}</Hero>;
};

HeroSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeroSection;
