import styled from 'styled-components';

import { easing } from '@styles/animation';

export const AccordionContainer = styled.div`
  z-index: 1;

  overflow: hidden;
`;

export const AccordionHeading = styled.div`
  position: relative;
  z-index: 1;

  margin: 0;

  ${({ theme }) =>
    theme.layouts.box(
      theme.accordionItem.color,
      theme.accordionItem.bg,
      theme.accordionItem.borderThin,
      0
    )}

  font-weight: ${({ theme }) => theme.accordionItem.fontWeight};
  font-size: ${({ theme }) => theme.accordionItem.fontSize}rem;
  line-height: ${({ theme }) => theme.accordionItem.lineHeight};
`;

export const AccordionTitleWrapper = styled.span`
  text-align: left;

  > * + * {
    display: block;

    margin-top: ${({ theme }) => theme.accordionItem.space}rem;
  }
`;

export const AccordionButton = styled.button`
  position: relative;
  z-index: 1;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: ${({ theme }) => theme.accordionItem.space}rem;

  border: 0;

  outline: 0;
  cursor: pointer;

  appearance: none;

  :focus ${AccordionTitleWrapper} {
    outline: 1px dotted ${({ theme }) => theme.colors.tertiary};
  }
`;

export const AccordionPanel = styled.section`
  position: relative;
  /* z-index: -1; */

  max-height: ${({ $expanded }) => ($expanded ? '4000px' : '0px')};

  overflow: hidden;

  transform: translateY(${({ $expanded }) => ($expanded ? '0px' : '-124px')});

  opacity: ${({ $expanded }) => ($expanded ? '1' : '0')};

  @media (prefers-reduced-motion: no-preference) {
    transition: transform 600ms ${easing.outQuint}, opacity 800ms,
      max-height 650ms ${easing.outQuint};
  }
`;

export const AccordionPanelContent = styled.div`
  ${({ theme }) =>
    theme.layouts.box(
      theme.accordionItem.panel.color,
      theme.accordionItem.panel.bg,
      theme.accordionItem.borderThin,
      theme.accordionItem.space
    )}

  padding-bottom: ${({ theme }) => theme.accordionItem.panel.spaceBottom}rem;

  font-weight: ${({ theme }) => theme.accordionItem.fontWeight};
  font-size: ${({ theme }) => theme.accordionItem.fontSize}rem;
  line-height: ${({ theme }) => theme.accordionItem.lineHeight};

  border-color: ${({ theme }) => theme.accordionItem.panel.bg};

  > * {
    margin: 0;
  }
`;
