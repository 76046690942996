import styled from 'styled-components';

export const Container = styled.section`
  margin: 96px 24px 128px;
`;

export const Wrapper = styled.div`
  max-width: 60ch;

  margin: auto;
`;

export const List = styled.ul`
  padding-left: 1ch;

  list-style-type: '- ';
`;

export const CTAWrapper = styled.div`
  text-align: right;
`;

export const Section = styled.section`
  margin: 0 0 ${56 / 22}em;
`;

export const SectionTitle = styled.h3`
  margin-bottom: 0;

  & + *,
  & + div p {
    margin-top: 0;
  }
`;
