// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  Container,
  TextContainer,
  TextWrapper,
  PictureContainer,
  Picture,
  breakpoint,
} from './Hero.styles';

const Hero = ({ pictures, primary, children }) => {
  const {
    childImageSharp: { desktop, mobile },
  } = pictures;

  const source = [
    {
      ...desktop,
      media: `(min-width: ${breakpoint}px)`,
      sizes: `(max-width: 1854px) ${(1088 / 1854) * 100}vw, 1088px`,
    },
    { ...mobile, sizes: '100vw' },
  ];

  return (
    <Container $primary={primary}>
      <TextContainer $primary={primary}>
        <TextWrapper>{children}</TextWrapper>
      </TextContainer>

      <PictureContainer $primary={primary}>
        <Picture fluid={source} objectFit='cover' loading='eager' />
      </PictureContainer>
    </Container>
  );
};

Hero.propTypes = {
  pictures: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      desktop: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired,
        srcSetWebp: PropTypes.string.isRequired,
        srcWebp: PropTypes.string.isRequired,
      }).isRequired,
      mobile: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired,
        srcSetWebp: PropTypes.string.isRequired,
        srcWebp: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  primary: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Hero.defaultProps = {
  primary: false,
};

export default Hero;

export const query = graphql`
  fragment HeroPicturesDesktop on ImageSharp {
    desktop: fluid(maxWidth: 1088, maxHeight: 400, quality: 100) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
      ...GatsbyImageSharpFluidLimitPresentationSize
    }
  }
  fragment HeroPicturesMobile on ImageSharp {
    mobile: fluid(maxWidth: 1024, maxHeight: 972, quality: 100) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
      ...GatsbyImageSharpFluidLimitPresentationSize
    }
  }
  fragment HeroPictures on File {
    childImageSharp {
      ...HeroPicturesDesktop
      ...HeroPicturesMobile
    }
  }
`;
