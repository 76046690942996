/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// styles
import { magnifyStyle } from '@styles/global';

// components
import Layout from '@components/Layout';

// views
import HeroSection from '@views/JobPageView/HeroSection';
import IntroSection from '@views/JobPageView/IntroSection';
import SEO from '@src/components/SEO';
import JobsListSection from '@src/views/JobPageView/JobsListSection/JobsListSection';

const JobsPage = ({ data }) => {
  const {
    jobs: { nodes: jobs = [] },
  } = data;

  const langLinks = [{ langKey: 'fr', href: '/emplois' }];

  const reducedJobs = jobs.map((job) => ({
    title: job.title,
    description: job._rawDescription,
    responsibilities: job.responsibilities,
    requirements: job.requirements,
    qualifications: job.qualifications,
  }));

  return (
    <Layout localeLinks={langLinks}>
      <SEO lang='en' langLinks={langLinks} title='Jobs' description='' />

      <HeroSection>
        <h1>Job opportunities</h1>
      </HeroSection>

      <IntroSection>
        <p css={magnifyStyle}>Join a great team and a stable company!</p>

        <p>
          Located in Sainte-Marguerite, Permafil is a manufacturing company that
          designs and fabricates metal storage products primarily for the
          large-scale construction and retail sectors. Permafil designs and
          manufactures commercial displays for the retail sector in addition to
          manufacturing a range of products intended for emergency services.
        </p>
      </IntroSection>

      <JobsListSection jobs={reducedJobs} />
    </Layout>
  );
};

JobsPage.propTypes = {
  data: PropTypes.shape({
    jobs: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export default JobsPage;

export const query = graphql`
  query JobsPageQuery {
    jobs: allSanityJob(filter: { localization: { locale: { eq: "en" } } }) {
      nodes {
        title
        responsibilities
        requirements
        qualifications
        _rawDescription(resolveReferences: { maxDepth: 10 })
        localization {
          locale
        }
        id
      }
    }
  }
`;
