// vendors
import styled, { css } from 'styled-components';
import GatsbyImage from 'gatsby-image';

import { greaterThan, lessThan } from '../../utils/mediaQuery';
import { h1Style } from '../../styles/global';

export const breakpoint = 1024;

export const TextContainer = styled.div`
  position: relative;

  display: flex;

  align-items: center;

  padding-left: max(calc(50% - 1408px / 2), 24px);

  ${lessThan(breakpoint)} {
    width: 100%;
    padding-right: max(calc(50% - 1408px / 2), 24px);

    ::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: -1;

      display: block;

      content: '';

      ${({ $primary }) =>
        !$primary &&
        css`
          background-color: ${({ theme }) => theme.hero.bg};
          mix-blend-mode: multiply;
        `}

      ${({ $primary }) =>
        $primary &&
        css`
          background-color: ${({ theme }) => theme.hero.primary.bg};
        `}
    }
  }

  ${greaterThan(breakpoint)} {
    z-index: 1;

    box-sizing: content-box;
    min-height: 400px;

    margin-right: 31px;

    padding-bottom: 64px;

    background-color: ${({ theme }) => theme.hero.bg};

    ${({ $primary }) =>
      $primary &&
      css`
        background-color: ${({ theme }) => theme.hero.primary.bg};
      `}

    ::after {
      position: absolute;

      bottom: 0;
      left: 100%;

      width: 0;
      height: 0;

      border-bottom: 464px solid transparent;
      border-left: 232px solid ${({ theme }) => theme.hero.bg};

      content: '';

      ${({ $primary }) =>
        $primary &&
        css`
          border-left-color: ${({ theme }) => theme.hero.primary.bg};
        `}
    }
  }
`;

export const TextWrapper = styled.div`
  > * {
    ${h1Style}

    small {
      display: block;

      font-weight: ${({ theme }) => theme.fontWeights.light};
      font-size: ${34 / 50}em;
      line-height: ${46 / 34};
    }
  }

  ${lessThan(breakpoint)} {
    > * {
      font-size: ${20 / 16}rem;

      small {
        font-size: ${16 / 20}em;
      }
    }
  }

  ${greaterThan(breakpoint)} {
    width: ${512 / 22}rem;
    max-width: 512px;
  }
`;

export const PictureContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: -2;

  overflow: hidden;

  ${greaterThan(breakpoint)} {
    position: relative;

    flex-grow: 1;

    height: 400px;

    background-color: ${({ theme }) => theme.hero.altBg};

    ${({ $primary }) =>
      $primary &&
      css`
        background-color: ${({ theme }) => theme.hero.primary.altBg};
      `}

    ::after {
      position: absolute;

      bottom: 0;
      left: 888px;

      width: 0;
      height: 0;

      border-top: 400px solid transparent;
      border-right: 200px solid ${({ theme }) => theme.hero.altBg};

      content: '';

      ${({ $primary }) =>
        $primary &&
        css`
          border-right-color: ${({ theme }) => theme.hero.primary.altBg};
        `}
    }
  }
`;

export const Picture = styled(GatsbyImage)`
  position: absolute !important;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  max-width: 1088px;
`;

export const Container = styled.header`
  position: relative;

  display: flex;

  overflow: hidden;

  color: ${({ theme }) => theme.hero.color};

  ${lessThan(breakpoint)} {
    align-items: flex-end;

    min-height: ${(304 / 320) * 100}vw;
  }

  ${({ $primary }) =>
    $primary &&
    css`
      color: ${({ theme }) => theme.hero.primary.color};
    `}
`;
