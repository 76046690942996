// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Wrapper } from './IntroSection.styles';

const IntroSection = ({ children }) => (
  <Container>
    <Wrapper>{children}</Wrapper>
  </Container>
);

IntroSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IntroSection;
