import React from 'react';
import PropTypes from 'prop-types';

import randomString from '@utils/math/randomString';

import {
  AccordionContainer,
  AccordionHeading,
  AccordionButton,
  AccordionTitleWrapper,
  AccordionPanel,
  AccordionPanelContent,
} from './AccordionItem.styles';

import ToggleButton from '../../images/ToggleIcon';

const AccordionItem = ({
  id,
  titleAs,
  title,
  children,
  expanded,
  onToggleClick,
}) => {
  const accordionItemId = id || randomString();

  return (
    <AccordionContainer>
      <AccordionHeading as={titleAs}>
        <AccordionButton
          id={`${accordionItemId}-header`}
          aria-controls={`${accordionItemId}-panel`}
          aria-expanded={expanded}
          onClick={onToggleClick}
        >
          <AccordionTitleWrapper>{title}</AccordionTitleWrapper>

          <ToggleButton
            label={expanded ? 'Réduire' : 'Développer'}
            toggled={expanded}
          />
        </AccordionButton>
      </AccordionHeading>

      <AccordionPanel
        id={`${accordionItemId}-panel`}
        aria-labelledby={`${accordionItemId}-header`}
        aria-hidden={!expanded}
        $expanded={expanded}
      >
        <AccordionPanelContent>{children}</AccordionPanelContent>
      </AccordionPanel>
    </AccordionContainer>
  );
};

AccordionItem.propTypes = {
  id: PropTypes.string,
  titleAs: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  onToggleClick: PropTypes.func,
};

AccordionItem.defaultProps = {
  id: undefined,
  expanded: false,
  onToggleClick: () => {},
};

export default AccordionItem;
