// vendors
import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@src/components/Accordion/Accordion';
import AccordionItem from '@src/components/AccordionItem/AccordionItem';
import BlockContent from '@src/components/BlockContent/BlockContent';
import { FormattedMessage } from 'react-intl';

import Button from '@components/Button';
import IconArrow from '@images/IconArrow';
import {
  Container,
  Wrapper,
  List,
  CTAWrapper,
  Section,
  SectionTitle,
} from './JobsListSection.styles';

// const messages = defineMessages({
//   productServiceSectionId: {
//     defaultMessage: 'produits-et-services',
//     description: 'Id of the product and service section in the business page',
//   },
// });

const JobsListSection = ({ jobs }) => {
  return (
    <Container>
      <Wrapper>
        <Accordion collapsible>
          {jobs.map((job) => (
            <AccordionItem title={job.title} titleAs='h2'>
              <Section>
                <SectionTitle>
                  <FormattedMessage
                    defaultMessage='Mise en contexte'
                    description='Description section in job accordion'
                  />
                </SectionTitle>

                <BlockContent blocks={job.description} />
              </Section>

              {job.responsibilities.length > 0 && (
                <Section>
                  <SectionTitle>
                    <FormattedMessage
                      defaultMessage='Les principales responsabilités'
                      description='Responsibilities section in job accordion'
                    />
                  </SectionTitle>

                  <List>
                    {job.responsibilities.map((responsibility) => (
                      <li
                        dangerouslySetInnerHTML={{ __html: responsibility }}
                      />
                    ))}
                  </List>
                </Section>
              )}

              {job.requirements.length > 0 && (
                <Section>
                  <SectionTitle>
                    <FormattedMessage
                      defaultMessage='Exigences du poste'
                      description='Requirements section in job accordion'
                    />
                  </SectionTitle>
                  <List>
                    {job.requirements.map((requirement) => (
                      <li dangerouslySetInnerHTML={{ __html: requirement }} />
                    ))}
                  </List>
                </Section>
              )}

              {job.qualifications.length > 0 && (
                <Section>
                  <SectionTitle>
                    <FormattedMessage
                      defaultMessage='Qualifications requises'
                      description='Qualifications section in job accordion'
                    />
                  </SectionTitle>
                  <List>
                    {job.qualifications.map((qualification) => (
                      <li dangerouslySetInnerHTML={{ __html: qualification }} />
                    ))}
                  </List>
                </Section>
              )}

              <CTAWrapper>
                <Button
                  tag='href'
                  href='mailto:info@permafil.com'
                  renderIcon={<IconArrow />}
                  outlined
                  primary
                >
                  <FormattedMessage
                    defaultMessage='Envoyez votre candidature'
                    description='CTA button in job accordion'
                  />
                </Button>
              </CTAWrapper>
            </AccordionItem>
          ))}
        </Accordion>
      </Wrapper>
    </Container>
  );
};

JobsListSection.propTypes = {
  jobs: PropTypes.arrayOf([
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.arrayOf(PropTypes.node).isRequired,
      responsibilities: PropTypes.arrayOf(PropTypes.string).isRequired,
      requirements: PropTypes.arrayOf(PropTypes.string).isRequired,
      qualifications: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ]),
};

JobsListSection.defaultProps = {
  jobs: [],
};

export default JobsListSection;
