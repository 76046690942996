import styled from 'styled-components';

export const Container = styled.section`
  margin: 96px 24px 128px;
`;

export const Wrapper = styled.div`
  max-width: 60ch;

  margin: auto;

  text-align: center;
`;
