// vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import AccordionItem from '@components/AccordionItem';

// styles
import { List, ListItem } from './Accordion.styles';

const Accordion = ({ multiple, collapsible, children }) => {
  const [expandedItems, setExpandedItems] = useState([]);

  const items = React.Children.toArray(children);

  const handleToggle = (itemIndex) => {
    let newArray = [...expandedItems];
    const arrayIndex = newArray.findIndex((index) => index === itemIndex);

    if (!collapsible && arrayIndex > -1) {
      return;
    }

    if (!multiple) {
      newArray = [];
    }

    if (arrayIndex > -1 && multiple) {
      newArray.splice(arrayIndex, 1);
    }

    if (arrayIndex < 0) {
      newArray.push(itemIndex);
    }

    setExpandedItems(newArray);
  };

  return (
    <List>
      {items.map((item) => (
        <ListItem key={item.key}>
          <AccordionItem
            {...item.props}
            onToggleClick={() => handleToggle(item.key)}
            expanded={expandedItems.includes(item.key)}
          />
        </ListItem>
      ))}
    </List>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  collapsible: PropTypes.bool,
  multiple: PropTypes.bool,
};

Accordion.defaultProps = {
  collapsible: false,
  multiple: false,
};

export default Accordion;
